import api from '@/config/api/live';
import {
  SITE,
  resolveEndpoint,
} from '@/config/constants';
import {
  BONUS_DEFAULT_IMG,
  BONUS_NAME_TO_TYPE_MAP,
  BONUS_STATUS_ACTIVE,
  REFRESH_BONUS_OVERVIEW,
  BONUS_MESSAGE_SHOW_PROCESSING,
  BONUS_MESSAGE_HIDE_PROCESSING,
} from '@/services/bonus/Bonus.enums';

import {
  USER_MESSAGE_QUEUE_MESSAGE,
  USER_MESSAGE_SHOW_MODAL,
} from '@/globals/enums/UserMessage.enums';
import translation from '@/plugins/translation';
import EventBus from '@/services/EventBus';
import { FortunaSite } from '@/globals/enums/enums';
import { gtmEvents } from '@/globals/enums/gtm.enums';

function bonusRequestFactory(endpoint, payload = {}) {
  payload.bonusId = payload.bonusId || null;

  return api.post(resolveEndpoint(endpoint), payload)
    .then(({ data }) => data);
}

export async function fetchBetslipBonusOverview(customerId) {
  return bonusRequestFactory(`BONUS_BETSLIP_OVERVIEW`, { customerId });
}

export async function fetchBonusMessages(username) {
  return bonusRequestFactory(`BONUS_FILTER`, { username });
}

export async function acceptBonus(username, bonusId) {
  return bonusRequestFactory(`BONUS_ACCEPT`, { username, bonusId });
}

export async function acceptBetslipBonus(username, bonusId) {
  return bonusRequestFactory(`BONUS_BETSLIP_ACCEPTATION`, { username, bonusId });
}

export async function declineBonus(username, bonusId) {
  return bonusRequestFactory(`BONUS_DECLINE`, { username, bonusId });
}

export const assembleBetslipBonusMessage = (username, {
  id, description: body, title, bonusType, condition, onAccepted, onDeclined,
}) => {
  title = title || translation.getTranslatedMessage(`live3.bonus.popup.defaultHeader`);

  return {
    id: `Bonus-${id}`,
    iframe: false,
    fullWidthIframe: true,
    centeredContent: true,
    important: true,
    header: title,
    onAccepted,
    onDeclined,
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "BonusMessage" */ `../../globals/components/UserMessage/BonusMessage`),
    componentData: {
      body,
      bonusType,
      condition,
      bonusId: id,
    },
    condition: {
      label: translation.getTranslatedMessage(`live3.bonus.popup.show.conditions`),
      onClick(refs) {
        const bonusMessage = refs.modalComponent;

        bonusMessage.isConditionVisible = !bonusMessage.isConditionVisible;
      },
    },
    buttons: [
      {
        text: translation.getTranslatedMessage(`live3.bonus.popup.decline`),
        color: `neutral`,
        async onClick(refs, showNextMessage) {
          showNextMessage();
        },
      },
      {
        text: translation.getTranslatedMessage(`live3.bonus.popup.accept`),
        color: `top-action`,
        async onClick(refs, showNextMessage) {
          EventBus.$emit(BONUS_MESSAGE_SHOW_PROCESSING);

          await acceptBetslipBonus(username, id).then(({ bonus }) => {
            EventBus.$emit(BONUS_MESSAGE_HIDE_PROCESSING);
            showNextMessage();

            if (bonus.status === BONUS_STATUS_ACTIVE) {
              EventBus.$emit(USER_MESSAGE_QUEUE_MESSAGE, onAccepted);
            } else {
              EventBus.$emit(USER_MESSAGE_QUEUE_MESSAGE, onDeclined);
            }

            EventBus.$emit(USER_MESSAGE_SHOW_MODAL);
          }).catch(() => {
            EventBus.$emit(BONUS_MESSAGE_HIDE_PROCESSING);
            showNextMessage();
            EventBus.$emit(USER_MESSAGE_QUEUE_MESSAGE, onDeclined);
            EventBus.$emit(USER_MESSAGE_SHOW_MODAL);
          });
        },
      },
    ],
  };
};

export const assembleBonusInfoMessage = ({ status, message, title, icon }) => {
  title = title || translation.getTranslatedMessage(`live3.bonus.popup.defaultHeader`);

  return {
    iframe: false,
    fullWidthIframe: true,
    centeredContent: true,
    important: true,
    header: title,
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "BonusPurchaseMessage" */ `../../globals/components/UserMessage/BonusPurchaseMessage`),
    componentData: {
      status,
      message,
      icon,
    },
    buttons: [
      {
        text: translation.getTranslatedMessage(`live3.bonus.popup.close`),
        color: `positive`,
        async onClick(refs, showNextMessage) {
          EventBus.$emit(REFRESH_BONUS_OVERVIEW);
          showNextMessage();
        },
      },
    ],
  };
};

export const assembleBonusMessage = (username, { id, description: body, title, bonusType, condition }) => {
  title = title || translation.getTranslatedMessage(`live3.bonus.popup.defaultHeader`);

  return {
    id: `Bonus-${id}`,
    iframe: false,
    fullWidthIframe: true,
    centeredContent: true,
    important: true,
    header: title,
    // eslint-disable-next-line max-len
    component: () => import(/* webpackChunkName: "UserChangePassword" */ `../../globals/components/UserMessage/BonusMessage`),
    componentData: {
      body,
      bonusType,
      condition,
      bonusId: id,
    },
    condition: {
      label: translation.getTranslatedMessage(`live3.bonus.popup.show.conditions`),
      onClick(refs) {
        const bonusMessage = refs.modalComponent;

        bonusMessage.isConditionVisible = !bonusMessage.isConditionVisible;
      },
    },
    buttons: [
      {
        text: translation.getTranslatedMessage(`live3.bonus.popup.decline`),
        color: `neutral`,
        async onClick(refs, showNextMessage) {
          await declineBonus(username, id);
          EventBus.$emit(gtmEvents.GTM_BONUS_POPUP_DECLINED);
          showNextMessage();
        },
      },
      {
        text: translation.getTranslatedMessage(`live3.bonus.popup.delay`),
        color: `primary`,
        onClick(refs, showNextMessage) {
          EventBus.$emit(gtmEvents.GTM_BONUS_POPUP_POSTPONED);
          showNextMessage();
        },
      },
      {
        text: translation.getTranslatedMessage(`live3.bonus.popup.accept`),
        color: `top-action`,
        async onClick(refs, showNextMessage) {
          await acceptBonus(username, id);
          EventBus.$emit(gtmEvents.GTM_BONUS_POPUP_ACCEPTED);
          showNextMessage();
        },
      },
    ],
  };
};

/**
 *
 * @param type {string}
 * @returns {string}
 */
export function getBonusImageName(type) {
  if (!type) return BONUS_DEFAULT_IMG;

  const bonusName = BONUS_NAME_TO_TYPE_MAP[String(type).toLowerCase()];

  if (!bonusName) return BONUS_DEFAULT_IMG;

  if (SITE === FortunaSite.CP) {
    return `/icons/bonus/casa/${bonusName}.svg`.toLowerCase();
  }
  if (SITE === FortunaSite.HR) {
    return `/icons/bonus/psk/${bonusName}.svg`.toLowerCase();
  }

  return `/icons/bonus/ftn/${bonusName}.svg`.toLowerCase();
}

/**
 * Getting icon after bonus purchase success/fail/...
 * @param type {string}
 * @returns {string}
 */
export function getBonusIconName(status) {
  if (!status) return BONUS_DEFAULT_IMG;

  const iconName = BONUS_NAME_TO_TYPE_MAP[String(status).toLowerCase()];

  if (!iconName) return BONUS_DEFAULT_IMG;

  return `/icons/bonus/icons/${iconName}.svg`;
}
