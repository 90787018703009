export const FEATURE_PHONE_VERIFICATION = `FEATURE_PHONE_VERIFICATION`;
export const FEATURE_START_MOBILEPHONE_VERIFICATION = `FEATURE_START_MOBILEPHONE_VERIFICATION`;
export const FEATURE_BETSLIP_BONUS_MESSAGE = `FEATURE_BETSLIP_BONUS_MESSAGE`;
export const FEATURE_BONUS_POINTS = `FEATURE_BONUS_POINTS`;
export const FEATURE_CASINO_TRANSFER = `FEATURE_CASINO_TRANSFER`;
export const FEATURE_COLLAPSED_LEGAL_BOX = `FEATURE_COLLAPSED_LEGAL_BOX`;
export const FEATURE_LANG_SWITCHER = `FEATURE_LANG_SWITCHER`;
export const FEATURE_LEGAL_ICONS = `FEATURE_LEGAL_ICONS`;
export const FEATURE_OVER18_DISABLED = `FEATURE_OVER18_DISABLED`;
export const FEATURE_TIME_SINCE_LAST_LOGIN = `FEATURE_TIME_SINCE_LAST_LOGIN`;
export const FEATURE_60_MINUTE_POPUP = `FEATURE_60_MINUTE_POPUP`;
export const FEATURE_BET_POINTS = `FEATURE_BET_POINTS`;
export const FEATURE_REGISTER_REDIRECT = `FEATURE_REGISTER_REDIRECT`;
export const FEATURE_REGULATORY_MESSAGE = `FEATURE_REGULATORY_MESSAGE`;
export const FEATURE_REGULATORY_MESSAGE_AFTER_LOGIN = `FEATURE_REGULATORY_MESSAGE_AFTER_LOGIN`;
export const FEATURE_LIMITED_OVERVIEW = `FEATURE_LIMITED_OVERVIEW`;
export const FEATURE_RGL_USER_ACCOUNT_LINK = `FEATURE_RGL_USER_ACCOUNT_LINK`;
export const FEATURE_CB_LIVE = `FEATURE_CB_LIVE`;
export const FEATURE_HIDE_EARLY_CASHOUT = `FEATURE_HIDE_EARLY_CASHOUT`;
export const FEATURE_CB_FULL_SOLUTION = `FEATURE_CB_FULL_SOLUTION`;
export const FEATURE_CB_FULL_SOLUTION_EMERGENCY_STOP = `FEATURE_CB_FULL_SOLUTION_EMERGENCY_STOP`;
export const FEATURE_TICKET_POTENTIAL_PAYOUT = `FEATURE_TICKET_POTENTIAL_PAYOUT`;
export const FEATURE_MARATHONS = `FEATURE_MARATHONS`;
export const FEATURE_CALCULATE_EC_VALUE = `FEATURE_CALCULATE_EC_VALUE`;
export const FEATURE_VUE_COOKIE_CONSENT = `FEATURE_VUE_COOKIE_CONSENT`;
export const FEATURE_NEW_LOGIN = `FEATURE_NEW_LOGIN`;
export const FEATURE_VUE_RESPONSIBLE_GAMING = `FEATURE_VUE_RESPONSIBLE_GAMING`;
export const FEATURE_VUE_NOTIFICATION_BANNER = `FEATURE_VUE_NOTIFICATION_BANNER`;
export const FEATURE_RGL_BANNER = `FEATURE_RGL_BANNER`;
export const FEATURE_NOTIFICATION_HUB = `FEATURE_NOTIFICATION_HUB`;
export const FEATURE_CHAT_SUPPORT_FE = `FEATURE_CHAT_SUPPORT_FE`;
export const FEATURE_BONUS_NOTIFICATION_POPUP = `FEATURE_BONUS_NOTIFICATION_POPUP`;
export const FEATURE_NEW_LOGIN_TIME_COUNTER = `FEATURE_NEW_LOGIN_TIME_COUNTER`;
