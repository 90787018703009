import URITemplate from 'urijs/src/URITemplate';

export const LOCATION_PROTOCOL = window.location.protocol;
export const LOCATION_PROTOCOL_SECURED = LOCATION_PROTOCOL === `https:`;
export const LOCATION_HOSTNAME = window.location.hostname;

export const topics = {
  marketData(site, marketId) {
    return `/topic/live3.marketdata.${site}.${marketId}`;
  },
  marketsCount(site) {
    return `/topic/live3.marketscount.${site}`;
  },
  matchData(site, matchId) {
    return `/topic/live3.matchdata.${site}.${matchId}`;
  },
  miniScoreboards(site) {
    return `/topic/live3.miniscoreboards.${site}`;
  },
  sportData(site) {
    return `/topic/live3.sportdata.${site}`;
  },
  topMarkets(site) {
    return `/topic/live3.topmarkets.${site}`;
  },
  winningBetslip(site, clientId) {
    return `/topic/live3.betslipdata.${site}.${clientId}`;
  },
  inAppNotification() {
    return `/user/topic/notifications`;
  },
};

export const subscriptionIds = {
  marketData(marketId) {
    return `sub-l3-marketdata-${marketId}`;
  },
  marketsCount() {
    return `sub-l3-marketscount`;
  },
  matchData(matchId) {
    return `sub-l3-match-${matchId}`;
  },
  miniScoreboards() {
    return `sub-l3-miniscoreboards`;
  },
  sportData() {
    return `sub-l3-sportdata`;
  },
  topMarkets() {
    return `sub-l3-topmarkets`;
  },
  winningBetslip(clientId) {
    return `sub-l3-betslip-${clientId}`;
  },
  inAppNotification() {
    return `sub-inappnotifications`;
  },
};

// provided via Webpack DefinePlugin
// eslint-disable-next-line no-undef
export const SITE = SITE_ENV;
// provided via Webpack DefinePlugin
// eslint-disable-next-line no-undef
export const VERSION = `OCP${BUILD_VERSION} @ ${BUILD_DATE}`;

const commonConstants = {
  TWITCH_API_URL: `${LOCATION_PROTOCOL}//player.twitch.tv/`,
  YOUTUBE_API_URL: `${LOCATION_PROTOCOL}//youtube.com/`,
  MIXER_API_URL: `${LOCATION_PROTOCOL}//mixer.com/`,
  BETBAZAR_API_URL: `${LOCATION_PROTOCOL}//smdvks.live/`,
  MP4_STREAM_TYPE: `video/mp4`,
  MIN_LEGAL_ODDS_VALUE: 1.01,
  // must match value in sizing.scss
  TOP_MARKET_3_VISIBILITY_BREAKPOINT: 1366,
  FAVORITES_SPORT_ID: `FAVORITES`,
  ALL_SPORT_ID: null,
  PARENT_URL: `${LOCATION_HOSTNAME}`,
};

const endpoints = ({ live3ApiPrefix = `` }) => ({
  LOGIN_APIGW: `_codexch`,
  CHECK_TOKEN: `check`,
  NOTIFICATION_TOKEN_APIGW: `_notification-token`,
  VALIDATE_SESSION: `v1/_validate-login-session`,
  LIGHWEIGHT_OVERVIEW: `/pams/api/v2/userInfo`,
  CONFIGURATION: `${live3ApiPrefix}config/fe`,
  CONFIGURATION_GLOBAL: `cms-client/configuration/web_global`,
  VERSION: `${live3ApiPrefix}version`,
  FEATURES: `${live3ApiPrefix}config/features`,
  EARLY_CASHOUT_CONFIGURATION: `${live3ApiPrefix}config/ec`,
  EARLY_CASHOUT_SELL: `${live3ApiPrefix}ec/sell`,
  EARLY_CASHOUT_RESOLVE: `${live3ApiPrefix}ec/resolve`,
  MATCH_OVERVIEW: `${live3ApiPrefix}live/matches/overview`,
  CALENDAR: `${live3ApiPrefix}live/matches/calendar`,
  MATCH_DETAIL: `${live3ApiPrefix}live/matches/detail/{matchId}`,
  USER_INFO: `${live3ApiPrefix}user/info`,
  LOGIN: `${live3ApiPrefix}user/login`,
  LOGOUT: `${live3ApiPrefix}user/logout`,
  LOGOUT_WITH_BEHAVIOUR: `${live3ApiPrefix}user/logout?behaviour={behavior}`,
  USER_REMINDER: `${live3ApiPrefix}user/autoreminder`,
  USER_PHONE_NUMBER_VERIFY: `${live3ApiPrefix}user/phone_number/verify`,
  LOCALIZATIONS: `${live3ApiPrefix}cms/messages/{locale}`,
  TICKET_INFO: `${live3ApiPrefix}live/ticket/{ticketId}`,
  TICKET_ADD_BET: `${live3ApiPrefix}live/ticket/{ticketId}/addbet`,
  TICKET_ACCEPT_CHANGES: `${live3ApiPrefix}live/ticket/{ticketId}/acceptchanges`,
  TICKET_REMOVE_BET: `${live3ApiPrefix}live/ticket/{ticketId}/removebet`,
  TICKET_SET_PAY_VALUE: `${live3ApiPrefix}live/ticket/{ticketId}/changepayval`,
  TICKET_CLEAR: `${live3ApiPrefix}live/ticket/{ticketId}/clear`,
  TICKET_SUBMIT: `${live3ApiPrefix}live/ticket/{ticketId}/accept`,
  TICKET_RESOLVE: `${live3ApiPrefix}live/ticket/{ticketId}/resolve`,
  TICKET_SET_PAYMENT_KIND: `${live3ApiPrefix}live/ticket/{ticketId}/setpaymentkind`,
  TICKET_SET_CHAMPIONSHIP: `${live3ApiPrefix}live/ticket/{ticketId}/championship/{championship}`,
  TICKET_CHANGE_MODE: `${live3ApiPrefix}live/ticket/{ticketId}/changemode`,
  TICKET_CREATE: `${live3ApiPrefix}live/ticket/{ticketId}/create`,
  TICKET_CHANGE_BET_GROUP: `${live3ApiPrefix}live/ticket/{ticketId}/changebetgroup`,
  TICKET_MARK_BET_VALUE: `${live3ApiPrefix}live/ticket/{ticketId}/markbetvalue`,
  TICKET_CHANGE_BET_VALUE: `${live3ApiPrefix}live/ticket/{ticketId}/changebetvalue`,
  TICKET_SAVE: `${live3ApiPrefix}ticket/{ticketId}/save`,
  CHAT_FETCH_INFO: `${live3ApiPrefix}chat`,
  LIVE_STREAM: `${live3ApiPrefix}livestream/{matchId}`,
  IMG_STREAM: `streaming/events/{eventId}/stream`,
  LIVEBOX_STREAM: `${LOCATION_PROTOCOL}//api.fortuna.livebox.cz/v1/createlive.e`,
  SESSION_CHECK: `${live3ApiPrefix}user/session`,
  SPORTSMAN_STREAM: `${LOCATION_PROTOCOL}//streamaccess.unas.tv/mobile/live/{partnerId}/{streamId}.xml`,
  TICKET_HISTORY: `betslip-history/list?source={source}&allLegs={allLegs}`,
  FETCH_MARKETS: `${live3ApiPrefix}live/matches/markets`,
  LOGGING: `live3/api/logging`,
  TRACKING_USER_TAGS: `${live3ApiPrefix}tracking/user/tags`,
  TRACKING_USER_BALANCE: `${live3ApiPrefix}tracking/user/balance`,
  TRACKING_USER_DETAILS: `${live3ApiPrefix}tracking/user/details`,
  TRACKING_USER_PAYMENT_STATISTICS: `${live3ApiPrefix}tracking/user/paymentstatistics`,
  TRACKING_USER_TRIGGER_CAMPAIGN: `${live3ApiPrefix}tracking/user/campaign/trigger`,
  TRACKING_USER_BONUSES: `${live3ApiPrefix}tracking/user/bonuses`,
  TRACKING_USER_BONUSES_ACTIVE: `${live3ApiPrefix}tracking/user/bonuses/active`,
  TRACKING_USER_BONUSES_OFFERED: `${live3ApiPrefix}tracking/user/bonuses/offered`,
  TRACKING_USER_BONUSES_HISTORY: `${live3ApiPrefix}tracking/user/bonuses/history`,
  WEB_MESSAGES: `${live3ApiPrefix}messages/wmp`,
  TIME: `cms-client/time`,
  CONSENTS: `${live3ApiPrefix}user/consents`,
  SET_CONSENT: `${live3ApiPrefix}user/consents/set`,
  RESPONSIBLE_GAMING: `${live3ApiPrefix}user/responsible-gaming-limit-url`,
  BONUS_FILTER: `${live3ApiPrefix}bonus/filter`,
  BONUS_ACCEPT: `${live3ApiPrefix}bonus/accept`,
  BONUS_DECLINE: `${live3ApiPrefix}bonus/decline`,
  BONUS_BETSLIP_OVERVIEW: `${live3ApiPrefix}bonus/betslipBonusOverview`,
  BONUS_BETSLIP_ACCEPTATION: `${live3ApiPrefix}bonus/betslipBonusAcceptation`,
  USER_HIDE_BALANCE: `${live3ApiPrefix}user/settings/hide-balance`,
  TICKET_TRY_MIRROR_TARGETS: `${live3ApiPrefix}live/ticket/{ticketId}/tryMirrorTargets`,
  EXTERNAL_EVENT: `external/event`,
  BETRADAR_WIDGET: `https://widgets.sir.sportradar.com/20cea5d206ca1aba24fa6004c21e40fb/widgetloader`,
  SEO_CONFIG: `${live3ApiPrefix}cms/seoConfig?url={url}`,
  LIVESTREAM_TOKEN: `${live3ApiPrefix}livestream/token`,
  ACTIVE_MARATHONS: `${live3ApiPrefix}marathon/list`,
  SEO_URL_BY_FIXTURE_ID: `${live3ApiPrefix}seo-url/event/by-fixture-id/{value}`,
});

const defaultIcon = `all`;

const prematchEndpoints = {
  TICKET_CHECK: `ajax/checkticket`,
  TICKET_DETAIL: `ticketdetail?id={ticketIdHashed}&source={ticketSource}`,
};

export const custom03Field = {
  CUSTOM03: `uam/customfields/custom03`,
};

export const GaIDs = {
  CZ: `UA-91309247-1`,
  SK: `UA-91309247-2`,
  PL: `UA-91309247-3`,
  RO: `UA-91309247-4`,
  CP: `UA-91309247-5`,
  HR: `UA-91309247-6`,
}[SITE];

export const LiveChatSection = {
  CZ: `/sazky-live`,
  SK: `/live-stavky`,
  PL: `/zaklady-live`,
  RO: `/pariuri-live`,
  CP: `/pariuri-live`,
  HR: `/kladjenje-uzivo`,
}[SITE];

// prefix set in main.js ("/live3/api")
export function resolveEndpoint(name, args = {}, usePrefix = true) {
  const live3ApiPrefix = usePrefix ? window.APIGW_LIVE_API_PREFIX : `${window.APIGW_URL}/`;

  return new URITemplate(endpoints({ live3ApiPrefix })[name]).expand(args);
}

export function resolveApiGwEndpoint(name, args = {}) {
  return resolveEndpoint(name, args, false);
}

export function resolvePrematchEndpoint(name, args = {}) {
  return new URITemplate(prematchEndpoints[name]).expand(args);
}

export const COMBINED_BETSLIP_ADD_BET = `COMBINED_BETSLIP_ADD_BET`;
export const COMBINED_BETSLIP_BET_REMOVED_FROM_OFFER = `COMBINED_BETSLIP_BET_REMOVED_FROM_OFFER`;
export const COMBINED_BETSLIP_SELECTION_PRODUCT_TYPE = `LIVE`;
export const CB_ENVIRONMENT_NAME = `COMBINED_BETSLIP_FULL_SOLUTION`;

export const CHAT_LIBRARY_NAME = `ChatForum`;

export function resolveLiveTicketEndpoint(name, args) {
  return resolveEndpoint(name, {
    ...args,
    // Live3 uses only single ticket
    ticketId: 1,
  });
}

export const LOGO_TYPE_DEFAULT = `default`;
export const LOGO_TYPE_ALTERNATIVE = `alternative`;

/**
 * Get logo path
 * @param size - 1,2,3,4
 * @param skin - fortuna, psk, casa, ...
 * @param type - default/alternative
 * @return {string} Path to logo resource
 */
export function getLogoPath(size, skin = SKIN, type = LOGO_TYPE_DEFAULT) {
  const sizeVariant = size === 1 ? `` : `@${size}x`;
  const typePath = type !== LOGO_TYPE_DEFAULT ? `/${type}` : ``;

  return `/logo/${skin}${typePath}/${skin}-logo${sizeVariant}.png`;
}

export default {
  topics,
  ...commonConstants,
  defaultIcon,
};
